// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--67+h1";
export var btnBlock = "banner-module--btnBlock--8Zi0T";
export var container = "banner-module--container--t0YZQ";
export var hideMobBlock = "banner-module--hideMobBlock--0MlG6";
export var img = "banner-module--img--lkDjz";
export var mobBtn = "banner-module--mobBtn--GhYFh";
export var red = "banner-module--red--WJhtO";
export var secondContainer = "banner-module--secondContainer--wYkkg";
export var section = "banner-module--section--Oe8oV";
export var text = "banner-module--text--ymbtK";